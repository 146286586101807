<template>
	<div class="pa-20 flex-column justify-space-between text-center ">
		<div class="flex-column justify-center " style="height: 100%; font-weight: 700">
			<div
				v-if="type"
				class="bg-credit-success"
			>
				<div class="mt-30"> {{ $language.mypage.credit.credit_regist_complete_txt }}</div>
			</div>
			<div
				v-else
				class="bg-credit-fail"
			>
				<div class="mt-30">
					<div class="color-red">{{ $language.mypage.credit.credit_regist_fail_txt }}</div>
					<div class="mt-10">{{ $language.mypage.credit.credit_regist_fail_txt_sub }}</div>
				</div>
			</div>
		</div>
		<div
			class="btn_area"
		>
			<button
				v-if="type"
				class="btn_l btn_fill_blue"
				@click="$emit('click')"
			>{{  $language.common.complete }}</button>
			<button
				v-else
				class="btn_l btn_fill_blue"
				@click="$emit('cancel')"
			>{{  $language.common.ok }}</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafiaCreditResult'
		, props: ['user', 'type']
		, data: function(){
			return {
				program: {
					name: 'mafiaCreditResult'
					, title: this.$language.mypage.credit_result
					, not_footer: true
					, not_header: true
					, type: 'mypage'
					, from: 'mafiaCredit'
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}

	}
</script>

<style>
	.bg-credit-success {
		background-image: url('../../assets/image/intro_character_2.png');
		background-position: center center;
		background-size: 120px;
		padding-top: 120px;
	}

	.bg-credit-fail {
		background-image: url('../../assets/image/intro_character_3.png');
		background-position: center center;
		background-size: 120px;
		padding-top: 150px;
	}

	.color-red { color: var(--red)}
</style>